<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <ValidationObserver v-slot="{ invalid }">
                  <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
                    <h1>ເຂົ້າລະບົບ</h1>
                    <!-- UserName input -->
                    <validation-provider rules="required" v-slot="{ errors }">
                      <CInput
                        placeholder="Username"
                        autocomplete="username"
                        prop="user_name"
                        v-model="form.user_name"
                      >
                        <template #prepend-content>
                          <CIcon name="cil-user" />
                        </template>
                      </CInput>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </validation-provider>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <CInput
                        placeholder="Password"
                        type="password"
                        autocomplete="curent-password"
                        prop="user_password"
                        v-model="form.user_password"
                      >
                        <template #prepend-content>
                          <CIcon name="cil-lock-locked" />
                        </template>
                      </CInput>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </validation-provider>
                    <CRow>
                      <CCol col="12" class="text-right">
                        <CButton
                          type="submit"
                          :disabled="invalid || loading"
                          color="info"
                          class="px-4"
                          data-style="expand-left"
                          style="width: 9rem"
                        >
                          <span v-if="!loading">ເຂົ້າລະບົບ</span>
                          <CSpinner
                            v-if="loading"
                            color="light"
                            style="width: 1.3rem; height: 1.3rem;"
                          />
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </ValidationObserver>
              </CCardBody>
            </CCard>
            <CCard
              color="info"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h1><b class="text-danger">3C</b>-LINK</h1>
                <p>
                  ລະບົບຈັດການຂໍ້ມູນລຸກຄ້າ 3C-LINK
                  <br />Version 1.0
                </p>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { accountLoginAPI } from "@/api/account";
import { encrypt } from "@/libs/crypto";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions } from "vuex";
export default {
  name: "Login",
  components: {ValidationObserver, ValidationProvider },
  data() {
    return {
      form: {
        user_name: "",
        user_password: "",
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions("account", ["login"]),
    handleSubmit() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 5000);

      let username = this.form.user_name;
      let password = encrypt(this.form.user_password);

      this.login({ username, password })
        .then((response) => {
          // location.reload();
          this.$router.push("/dashboard");
          this.$awn.success("ຍິນດີຕ້ອນຮັບເຂົ້າລະບົບ");
          this.loading = false;
          
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
          this.loading = false;
        });
    },
  },
};
</script>
